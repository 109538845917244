import { computed, Ref, unref } from '@vue/composition-api';

export default function useCurrencyVModel(model: Ref<any>) {
  const formatter = (v: string) => {
    const formatted = String(v).replace(
      /(\d{1,3})(\d{1,3})(\d{1,4})/g,
      '$1-$2-$3'
    );
    console.log(formatted);

    return formatted;
  };

  const reformat = (v: string) =>
    Number(String(v).split('-').join(''));

  return computed({
    get: () => formatter(unref(model)),
    set: (v: any) => (model.value = reformat(v))
  });
}

export { useCurrencyVModel };
